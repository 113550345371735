@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400;500;600;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

input {
  -webkit-appearance: searchfield;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: white #ddd;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 20px;
  border: 3px solid #fff;
}
.mapboxgl-popup {
  min-width: 300px;
  max-width: 350px;
  padding: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.mapboxgl-popup-content {
  margin: 0 !important;
  padding: 0 !important;
  bottom: 30px;
}

.mapboxgl-popup-close-button {
  right: 10px;
  margin-top: 5px;
  z-index: 100;
  margin-right: 5px;
  height: 28px !important;
  width: 28px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: white !important;
  border-radius: 100px !important;
  font-size: 150% !important;
}

.mapboxgl-popup-tip {
  bottom: 30px !important;
  display: none;
}

.jobPosting ul li {
  list-style-type: disc;
  /* list-style-position: inside; */
  margin-left: 15px;
  /* list-style-color: #ffd29c; */
}

.jobPosting h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.jobPosting p {
  margin-bottom: 15px;
}
